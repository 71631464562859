<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="transactionData === undefined"
    >
      <h4 class="alert-heading">
        {{ t('Error fetching transaction data') }}
      </h4>
      <div class="alert-body">
        {{ t('No transaction found with this transaction id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          {{ t('Transaction List') }}
        </b-link>
        {{ t('for other transactions.') }}
      </div>
    </b-alert>
    <validation-observer
      v-show="!transactionDataLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmitSave)"
        @reset.prevent="resetForm"
      >
        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="12"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >

              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        swm
                      </h3>
                    </div>
                    <!-- <p class="card-text mb-25">
                      company name, address, itn
                    </p>
                    <p class="card-text mb-25">
                      bank account, etc
                    </p>
                    <p class="card-text mb-0">
                      +998946393966
                    </p> -->
                    <!-- <b-card-text class="mb-25">
                      Office 149, 450 South Brand Brooklyn
                    </b-card-text>
                    <b-card-text class="mb-25">
                      San Diego County, CA 91905, USA
                    </b-card-text>
                    <b-card-text class="mb-0">
                      +1 (123) 456 7891, +44 (876) 543 2198
                    </b-card-text> -->
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="">
                        {{ t('Add transaction') }}
                      </h4>
                      <!-- <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="transactionData.id"
                          disabled
                        />
                      </b-input-group> -->
                    </div>
                    <!-- <div class="d-flex align-items-right justify-content-end mb-1">
                      <h4 class="invoice-title">
                        {{ t('Приход товара') }}
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="transactionData.id"
                          disabled
                        />
                      </b-input-group>
                    </div> -->
                    <div class="d-flex align-items-center justify-content-end">
                      <span class="title align-middle mb-1">
                        {{ t('Date') }}:
                      </span>
                      <validation-provider
                        #default="validationContext"
                        :name="t('Date')"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          class="invoice-edit-input-group"
                        >
                          <flat-pickr
                            v-model="transactionData.date"
                            class="form-control invoice-edit-input"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="transactionData.dueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div> -->
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Activity') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Activity')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select activity -->
                        <v-select
                          ref="refActivityOpt"
                          v-model="transactionData.activity"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="activities"
                          label="name"
                          input-id="purchase-data-client"
                          :clearable="false"
                          @input="val => getSubOptions(val)"
                        >
                          <template #list-footer>
                            <!-- <li
                              v-b-toggle.sidebar-purchase-add-new-warehouse
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new activity') }}</span>
                            </li> -->
                            <li
                              v-if="activities.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                                small
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, type }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-${type === 'income' ? 'success' : type === 'expense' ? 'danger' : 'primary'}`"
                              >
                                <small>{{ type }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, type, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div>
                                <span class="align-middle"> {{ name }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-secondary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-${type === 'income' ? 'success' : type === 'expense' ? 'danger' : 'primary'}`"
                                class="mr-55"
                              >
                                <small>{{ type }}</small>
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected activity -->
                    <div
                      v-if="transactionData.activity"
                      class="mt-1"
                    >
                      <!-- <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(Math.floor(Math.random()*15)*1000000-3000000)}`"
                        >
                          <small>{{ t('Ostatka') }}: </small>
                          {{ label }}
                          {{ `${Math.floor(Math.random()*15)*1000000-3000000} ${ t('t') }` }}
                        </b-badge>
                      </li> -->
                      <!-- <h6 class="mb-1">
                        {{ t('Description of activity') }}:
                      </h6> -->
                      <!--<table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Product1:
                            </td>
                            <td><span class="font-weight-bold">110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Product2:
                            </td>
                            <td><span class="font-weight-bold">10.55</span></td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </b-col>

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Related from') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Related from')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refSubOpt"
                          v-model="transactionData.from_sub"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="accounts"
                          label="name"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        >
                          <template #list-footer>
                            <li
                              v-b-toggle.add-new-account-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New Account') }}</span>
                            </li>
                            <li
                              v-if="accounts.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, balance }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, balance }">
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ name }}</span>
                                <!-- <b-badge
                                  v-if="product.id === 2"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge> -->
                              </div>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                class="pr-60"
                              >
                                {{ balance }}
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Related to') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Related to')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refSubOpt"
                          v-model="transactionData.to_sub"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suboptions"
                          label="firstname"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        >
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #list-footer
                          >
                            <li
                              v-b-toggle.sidebar-purchase-add-new-product
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new account') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #list-footer
                          >
                            <li
                              v-b-toggle.add-new-user-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new user') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #list-footer
                          >
                            <li
                              v-b-toggle.sidebar-purchase-add-new-supplier
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New Customer') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else
                            #list-footer
                          >
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #selected-option="{ name, balance }"
                          >
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUpCustom(balance, 1) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #selected-option="{ firstname, surname, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstname} ${surname}`)"
                                  :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstname} ${surname}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUp(parseFloat(balance), 2) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #selected-option="{ firstName, lastName, active, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstName} ${lastName}`)"
                                  :variant="`light-${resolveUserAvatarVariant(active)}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstName} ${lastName}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUpCustom(balance, 1) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else
                            #selected-option
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #option="{ name, balance }"
                          >
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ name }}</span>
                                <!-- <b-badge
                                  v-if="product.id === 2"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge> -->
                              </div>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                class="pr-60"
                              >
                                {{ roundUpCustom(balance, 1) }}
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #option="{ firstName, lastName, active, balance }"
                          >
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstName} ${lastName}`)"
                                  :variant="`light-${resolveUserAvatarVariant(active)}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstName} ${lastName}` }}
                                </span>
                              </span>
                              <b-badge
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #option="{ firstname, surname, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstname} ${surname}`)"
                                  :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstname} ${surname}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUp(parseFloat(balance), 2) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else
                            #option
                          >
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Received') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Received')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refRespOpt"
                          v-model="transactionData.rec_user_id"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="receivers"
                          label="name"
                          input-id="purchase-data-rec_user_id"
                          :clearable="false"
                        >
                          <template #list-footer>
                            <li
                              v-b-toggle.add-new-rec-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new receiver') }}</span>
                            </li>
                            <li
                              v-if="users.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                          <template #option="{ name }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Currency') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Currency')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refCurrency"
                          v-model="transactionData.currency"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['USD','UZS']"
                          input-id="purchase-data-currency"
                          :clearable="false"
                        >
                        <!-- <template #list-footer>
                          <li
                            v-if="users.length === 0"
                            class="text-center text-primary my-2"
                          >
                            <b-spinner
                              class="align-middle mr-1"
                            />
                            <strong>Загрузка...</strong>
                          </li>
                        </template> -->
                        <!-- <template #selected-option="{ name, email, active }">
                          <b-avatar
                            size="26"
                            :text="avatarText(name)"
                            :variant="`light-${resolveUserAvatarVariant(active)}`"
                          />
                          <span class="ml-50 d-inline-block align-middle">
                            {{ name }}
                          </span>
                        </template>
                        <template #option="{ name, email, active }">
                          <b-avatar
                            size="26"
                            :text="avatarText(name)"
                            :variant="`light-${resolveUserAvatarVariant(active)}`"
                          />
                          <span class="ml-50 d-inline-block align-middle">
                            {{ name }}
                          </span>
                        </template> -->
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Payment Type') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Payment Type')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refPayment_type"
                          v-model="transactionData.payment_type"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Нал.','Безнал']"
                          input-id="purchase-data-payment_type"
                          :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Amount') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Amount')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.amount"
                          :state="getValidationState(validationContext)"
                          type="text"
                          class="mb-2"
                          :formatter="formatAmount"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Rate') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Rate')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.rate"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('USD') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('USD')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.usd"
                          :state="getValidationState(validationContext)"
                          type="text"
                          class="mb-2"
                          :formatter="formatAmount"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="3"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Commision') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Commision')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.commision"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-editable-table
                    v-if="transactionData.currency == 'UZS' && transactionData.payment_type == 'Безнал'"
                    v-model="usersEdit"
                    :busy="loadingEdit"
                    bordered
                    class="editable-table"
                    :fields="fieldsEdit"
                    @input-change="handleInput"
                    @blur-change="handleInput"
                  >
                    <template #cell(amount_usd)="data">
                      {{ roundUpCustom(data.value, 2) }}
                    </template>
                    <template #cell(amount_uzs)="data">
                      <span v-if="(data.value=='')" style="background:#f3f2f7;" />
                      <span v-else>{{ roundUpCustom(data.value, 2) }}</span>
                    </template>
                    <template #cell(subrate)="data">
                      <span v-if="(data.value=='' && data.value !== 0)" style="background:#f3f2f7;" />
                      <span v-else>{{ roundUpCustom(data.value, 1) }}</span>
                    </template>
                    <template #cell(commision)="data">
                      <span v-if="(data.value=='' && data.value !== 0)" style="background:#f3f2f7;" />
                      <span v-else>{{ roundUpCustom(data.value, 0) }}</span>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-editable-table>
                </b-row> -->
              </b-card-body>

              <!-- Items Section -->
              <b-card-body
                v-if="transactionData.activity!==null && transactionData.activity.id === 7 && transactionData.currency == 'UZS' && transactionData.payment_type == 'Безнал'"
                class="invoice-padding form-item-section"
              >
                <!-- <b-card-body
                class="invoice-padding form-item-section"
              > -->
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-sm-flex ml-2">
                        <b-row class="flex-grow-1 px-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2">
                            {{ t('Activity') }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ `СУММА USD` }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ `СУММА UZS` }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ `КУРС` }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ `КОММИСИЯ %` }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ `ПОСТАВЩИК` }}
                          </b-col>
                        </b-row>
                        <!-- <div class="form-item-action-col" /> -->
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div
                        v-for="(item, index) in usersEdit"
                        :key="index"
                        class="d-flex border rounded"
                      >
                        <b-row
                          class="flex-grow-1 px-2 pt-1 pb-1"
                        >
                          <b-col cols="12" lg="2">
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                t('Activity')
                              }}</label>
                              <b-form-input
                                v-model="item.state"
                                type="text"
                                class="mb-0"
                                size="sm"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                `СУММА USD`
                              }}</label>
                              <b-form-input
                                :value="roundUpCustom(item.amount_usd)"
                                type="text"
                                class="mb-0"
                                size="sm"
                                readonly
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" lg="2">
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                `СУММА UZS`
                              }}</label>
                              <b-form-input
                                :value="roundUpCustom(item.amount_uzs)"
                                type="text"
                                class="mb-0"
                                :style="(item.id>1?`background-color:#a6a4ab;`:``)"
                                size="sm"
                                readonly
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" lg="2">
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                `КУРС`
                              }}</label>
                              <b-form-input
                                v-model="item.subrate"
                                type="number"
                                class="mb-0"
                                :style="(item.id>1?`background-color:#a6a4ab;`:``)"
                                size="sm"
                                :readonly="item.id>1"
                                @input.native="handleChange('subrate')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" lg="2">
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                `КОММИСИЯ %`
                              }}</label>
                              <b-form-input
                                v-model="item.commision"
                                type="number"
                                class="mb-0"
                                size="sm"
                                :readonly="item.id>1"
                                :style="(item.id>2?`background-color:#a6a4ab;`:``)"
                                @input.native="handleChange('commision')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col
                            v-if="index === 1"
                            cols="12"
                            lg="2"
                          >
                            <b-form-group
                              class="mb-0"
                            >
                              <label class="d-inline d-lg-none">{{
                                `Поставщик`
                              }}</label>
                              <v-select
                                ref="refSubOpt"
                                v-model="item.supplier"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="supplierOptions"
                                label="firstname"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Select Item"
                                :searchable="false"
                                @input="val => getItemSupplier(val, item)"
                              >
                                <template
                                  #list-footer
                                >
                                  <li
                                    v-if="supplierOptions.length === 0 && item.supplier.firstname !== undefined"
                                    class="text-center text-primary my-2"
                                  >
                                    <b-spinner
                                      class="align-middle mr-1"
                                    />
                                    <strong>Загрузка...</strong>
                                  </li>
                                </template>
                                <template
                                  #selected-option="{ firstname, surname }"
                                >
                                  <li class="d-flex justify-content-between align-items-center">
                                    <span class="align-middle">
                                      {{ `${firstname} ${surname}` }}
                                    </span>
                                  </li>
                                </template>
                                <template
                                  #option="{ firstname, surname }"
                                >
                                  <li class="d-flex justify-content-between align-items-center">

                                    <span class="align-middle">
                                      {{ `${firstname} ${surname}` }}
                                    </span>
                                  </li>
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  {{ t('Add Item') }}
                </b-button> -->
              </b-card-body>
              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">{{ t('Note') }}: </span>
                <b-form-textarea v-model="transactionData.comments" />
              </b-card-body>
            </b-card>

          </b-col>
        </b-row>
        <b-row>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="4"
            class="invoice-actions"
          >

            <!-- Action Buttons -->
            <b-card>
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                  @click.prevent="handleSubmit(onSubmitSave)"
                >
                  <b-spinner
                    v-if="submitOnProcess.save"
                    small
                    type="grow"
                  />
                  {{ t('Add') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  block
                  class="mt-0"
                  :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                  :to="{ name: 'apps-transaction-list'}"
                >
                  {{ t('Cancel') }}
                </b-button>
              </b-button-group>
            </b-card>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div
      v-show="transactionDataLoading"
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>{{ t('Загрузка') }}...</strong>
    </div>
    <transaction-sidebar-add-new-suplier
      @refetch-data="refetchSuppliers"
    />
    <transaction-sidebar-add-new-warehouse
      @refetch-data="refetchActivities"
    />
    <transaction-sidebar-add-new-product
      @refetch-data="refetchSubOptions"
    />
    <transaction-sidebar-add-new-kassa
      @refetch-data="refetchKassas"
    />
    <transaction-sidebar-add-new-rec
      @refetch-data="refetchRec"
    />
    <user-list-add-new
      v-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
      is-add-new-user-sidebar-sub
      @refetch-data="refetchSubOpt"
    />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import router from '@/router'
import {
  ref, onUnmounted, watchEffect, watch, computed,
} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Validation
import {
  required, alphaNum, email, length, min, max_value,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { formatAmount, roundUp, roundUpCustom, toastError } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import BEditableTable from 'bootstrap-vue-editable-table'
import {
  BRow, BCol, BCard, BCardBody, BButton, BButtonGroup, BForm, BFormInput, BFormInvalidFeedback, BFormGroup, BFormTextarea, VBToggle, BSpinner, BBadge, BAlert, BLink, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import transactionStoreModule from '../transactionStoreModule'
import TransactionSidebarAddNewSuplier from '../TransactionSidebarAddNewSuplier.vue'
import TransactionSidebarAddNewWarehouse from '../TransactionSidebarAddNewWarehouse.vue'
import TransactionSidebarAddNewProduct from '../TransactionSidebarAddNewProduct.vue'
import TransactionSidebarAddNewKassa from '../TransactionSidebarAddNewKassa.vue'
import TransactionSidebarAddNewRec from '../TransactionSidebarAddNewRec.vue'
import UserListAddNew from '../../user/users-list/UserListAddNew.vue'
import userStoreModule from '../../user/userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BButtonGroup,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // BInputGroup,
    // BInputGroupPrepend,
    BFormTextarea,
    // BFormCheckbox,
    BSpinner,
    // BPopover,
    BBadge,
    flatPickr,
    vSelect,
    Logo,
    TransactionSidebarAddNewSuplier,
    TransactionSidebarAddNewWarehouse,
    TransactionSidebarAddNewProduct,
    TransactionSidebarAddNewKassa,
    TransactionSidebarAddNewRec,
    UserListAddNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormFile,
    BAlert,
    BLink,
    // BMedia,
    BAvatar,
    // BEditableTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
      max_value,
    }
  },
  watch: {
    transactionData(value) {
      console.log('pd updated', value)
      // console.log(this.$refs.refFile)
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.transactionData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    changeHeightInItemForm() {
      this.$refs.form.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.transactionData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
  },
  setup() {
    const toast = useToast()
    const TRANSACTION_APP_STORE_MODULE_NAME = 'app-transaction'
    const { t } = useI18nUtils()
    const totalWeightActivator = ref(false)
    const refRespOpt = ref(null)

    // Register module
    if (!store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_APP_STORE_MODULE_NAME, transactionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.unregisterModule(TRANSACTION_APP_STORE_MODULE_NAME)
    })

    const users = ref([])
    store.dispatch('app-transaction/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    const itemFormBlankItem = {
      product: null,
      cost: null,
      quantity: null,
      weight: null,
      subItems: [
        {
          weight: null,
        },
      ],
      description: '',
    }

    const blanktransactionData = {
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      activity: null,
      from_sub: null,
      to_sub: null,
      amount: null,
      currency: 'USD',
      rec_user_id: null,
      payment_type: 'Нал.',
      commision: 100,
      rate: null,
      usd: null,
      comments: '',
    }

    // const transactionData = ref({})

    const activities = ref([])
    const clientObject = ref([])
    store.dispatch('app-transaction/fetchActivities')
      .then(response => {
        clientObject.value = response.data.records.find(row => row.name === 'Оплата клиента')
        activities.value = response.data.records
        return response.data.records
      })

    const transactionData = ref({
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      activity: router.currentRoute.params.id ? clientObject : null,
      from_sub: null,
      to_sub: null,
      amount: null,
      currency: 'USD',
      rec_user_id: null,
      payment_type: 'Нал.',
      commision: 100,
      rate: null,
      usd: null,
      comments: '',
    })

    const suppliers = ref([])
    store.dispatch('app-transaction/fetchSupliers')
      .then(response => {
        suppliers.value = response.data.records
        return response.data.records
      })

    const resettransactionData = () => {
      transactionData.value = JSON.parse(JSON.stringify(blanktransactionData))
    }

    const supplierOptions = ref([])

    store.dispatch('app-transaction/getSubOptions', { related_to: 'Clients', subtype: 'supplier' })
      .then(response => {
        supplierOptions.value = response.data.records
      })

    const suboptions = ref([])

    const getSubOptions = activity => {
      // console.log(activity)
      transactionData.value.to_sub = null
      store.dispatch('app-transaction/getSubOptions', activity)
        .then(response => {
          suboptions.value = response.data.records
        })
    }
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const accounts = ref([])

    store.dispatch('app-transaction/getSubOptions', { related_to: 'Accounts' })
      .then(response => {
        accounts.value = response.data.records
      })

    const receivers = ref([])

    store.dispatch('app-transaction/getSubOptions', { related_to: 'Receivers' })
      .then(response => {
        receivers.value = response.data.records
      })

    const relatedto = computed(() => router.currentRoute.params.target)

    watch(() => relatedto.value, target => {
      console.log(target)
      if (target != null) {
        //
      }
    })

    const transactionDataLoading = ref(false)
    if (router.currentRoute.params.id) {
      transactionDataLoading.value = true
      store.dispatch('app-transaction/fetchSale', { id: router.currentRoute.params.id })
        .then(response => {
          if (response.data === null) {
            transactionData.value = undefined
          } else if (response.data.client_id !== null) {
            const item = response.data
            // getSubOptions(clientObject.value)
            transactionData.value.date = item.date
            store.dispatch('app-transaction/getSubOptions', { related_to: 'Clients' })
              .then(subresponse => {
                suboptions.value = subresponse.data.records
                transactionData.value.to_sub = subresponse.data.records.find(row => row.id === item.client_id)
              })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'This sale is not correct',
              },
            })
          }
        })
        .catch(() => {
          transactionData.value = undefined
        })
        .finally(() => {
          transactionDataLoading.value = false
        })
    }

    const updateSubItemQty = (index, val) => {
      // console.log(index, val, transactionData.value.items[index].subItems)
      const arr = []
      for (let i = 0; i < val; i = 1 + i) {
        arr.push({ weight: 0 })
      }
      transactionData.value.items[index].subItems = arr
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettransactionData)

    watchEffect(() => console.log('router.currentRoute.params.target', router.currentRoute.params.target))
    watchEffect(() => console.log('suboptions.value', suboptions.value))

    const refSupplierOpt = ref(null)
    const refActivityOpt = ref(null)
    // const refKassaOpt = ref(null)
    const refCarOpt = ref(null)
    const refSubOpt = ref(null)

    const refetchKassas = val => {
      accounts.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }
    const refetchRec = val => {
      receivers.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }
    const refetchSubOpt = val => {
      console.log('refetchSubOpt', val)
      suboptions.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }

    const refetchSuppliers = val => {
      suppliers.value.unshift(val)
      refSupplierOpt.value.$emit('input', val)
      // transactionData.value.supplier = val
      // refSupplierOpt.value.$emit('option:selected', val)
      // setTimeout(() => {
      // refSupplierOpt.value.$emit('close')
      // }, 1000)
      // this.$nextTick(() => {
      //   refSupplierOpt.value.close()
      // })

      // store.dispatch('app-transaction/fetchSupliers')
      //   .then(response => { suppliers.value = response.data.records })
    }

    const refetchActivities = val => {
      activities.value.unshift(val)
      refActivityOpt.value.$emit('input', val)
      // store.dispatch('app-transaction/fetchActivities')
      //   .then(response => { activities.value = response.data.records })
    }

    const refetchSubOptions = val => {
      const newVal = {
        product: val,
        itemTitle: `${val.code} ${val.name}`,
        cost: val.init_price,
        quantity: val.quantity,
        weight: 0,
        subItems: [
          {
            weight: 0,
          },
        ],
        description: val.description,
      }
      suboptions.value.unshift(newVal)
    }

    const usersEdit = ref([
      {
        id: 0, state_id: 7, state: 'Оплата клиента', amount_usd: 0, amount_uzs: 0, subrate: 0, commision: 100
      },
      {
        id: 1, state_id: 1, state: 'Оплата поставщику', amount_usd: 0, amount_uzs: 0, subrate: 0, commision: 100, supplier: ''
      },
      {
        id: 2, state_id: 11, state: 'Прочие доходы', amount_usd: 0, amount_uzs: '', subrate: '', commision: 0, _cellVariants: { amount_uzs: 'secondary', subrate: 'secondary' }
      },
      {
        id: 3, state_id: 17, state: 'Прочие доходы (курс)', amount_usd: 0, amount_uzs: '', subrate: '', commision: '', _cellVariants: { amount_uzs: 'secondary', subrate: 'secondary', commision: 'secondary' }
      },
    ])
    const handleChange = (field, on = true) => {
      // console.log(field, e.target.value)
      const newArr = usersEdit.value
      // newArr[item.id].amount_usd = item.subrate > 0 ? item.commision * (item.amount_uzs / item.subrate) : 0
      usersEdit.value = usersEdit.value.map(user => {
        const newItem = user
        if (field === 'commision' || field === 'subrate') {
          if (user.id === 0) {
            newItem.amount_usd = user.subrate > 0 ? ((user.commision) / 100) * (user.amount_uzs / user.subrate) : 0
            if (on) {
              transactionData.value.amount = formatAmount(`${user.amount_uzs}`)
              transactionData.value.rate = user.subrate
              transactionData.value.commision = user.commision
            }
          }
          if (user.id === 1) {
            newItem.amount_usd = user.subrate > 0 ? ((user.commision) / 100) * (user.amount_uzs / user.subrate) : 0
          }
          if (user.id === 2) {
            newItem.commision = newArr[1].commision - newArr[0].commision
            newItem.amount_usd = (newArr[0].amount_uzs * ((newItem.commision) / 100)) / newArr[0].subrate
            if (newItem.amount_usd < 0) {
              newItem.state = 'Прочие расходы'
              newItem.state_id = 12
            } else {
              newItem.state = 'Прочие доходы'
              newItem.state_id = 11
            }
          }
          if (user.id === 3) {
            newItem.amount_usd = newArr[1].amount_usd - (newArr[0].amount_usd + newArr[2].amount_usd)
            if (newItem.amount_usd < 0) {
              newItem.state = 'Прочие расходы (курс)'
              newItem.state_id = 16
            } else {
              newItem.state = 'Прочие доходы (курс)'
              newItem.state_id = 17
            }
          }
        }
        return newItem
      })
      console.log('usersEdit', usersEdit)
    }

    const calcExchange = async () => {
      const tAmount = transactionData.value.amount.length > 0 ? parseFloat(transactionData.value.amount.replace(/ /g, '')) : 0

      transactionData.value.usd = roundUpCustom(((transactionData.value.commision / 100) * tAmount) / parseFloat(transactionData.value.rate), 2)
      if (transactionData.value?.activity?.id === 7 && transactionData.value.currency === 'UZS' && transactionData.value.payment_type === 'Безнал') {
        usersEdit.value = usersEdit.value.map(item => {
          const newItem = item
          if (item.id === 0) {
            newItem.amount_uzs = tAmount
            newItem.commision = transactionData.value.commision
            newItem.subrate = transactionData.value.rate
            newItem.amount_usd = newItem.subrate > 0 ? ((newItem.commision) / 100) * (newItem.amount_uzs / newItem.subrate) : 0
          }
          if (item.id === 1) {
            newItem.amount_uzs = tAmount
            newItem.subrate = transactionData.value.rate
            newItem.amount_usd = newItem.subrate > 0 ? ((newItem.commision) / 100) * (newItem.amount_uzs / newItem.subrate) : 0
          }
          return newItem
        })
        handleChange('subrate', false)
      }
    }

    watch(() => [transactionData.value.amount, transactionData.value.rate, transactionData.value.commision], ([amount, rate, commision]) => {
      console.log(amount, rate, commision)
      calcExchange()
    })

    const submitOnProcess = ref({
      save: false,
      saveandsell: false,
      todraft: false,
    })
    const onSubmitSave = async () => {
      // Set state
      submitOnProcess.value.save = true

      try {
        // Check if usersEdit's second row's supplier key does not equal to empty
        const secondRowSupplier = usersEdit.value[1].supplier
        if (secondRowSupplier === '') {
          throw new Error('Error adding new purchase: Second row supplier not selected')
        }

        // Prepare the payload
        const payload = {
          date: transactionData.value.date,
          activity_id: transactionData.value.activity.id,
          from_sub_id: transactionData.value.from_sub ? transactionData.value.from_sub.id : null,
          to_sub_id: transactionData.value.to_sub.id,
          amount: parseFloat(transactionData.value.amount.replace(/ /g, '')),
          currency: transactionData.value.currency,
          rate: transactionData.value.rate,
          commision: transactionData.value.commision,
          rec_user_id: transactionData.value.rec_user_id?.id,
          comments: transactionData.value.comments,
          type: transactionData.value.payment_type,
          status: 'Pending',
          usersEdit: usersEdit.value,
          client_id: usersEdit.value[1].supplier
        }

        // Call the API to add new purchase
        const response = await store.dispatch('app-transaction/addTransaction', payload)

        // Show success toast notification and navigate to the transaction list page
        if (response?.data?.responseStatusCode === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          router.push({ name: 'apps-transaction-list' })
        } else {
          throw new Error(`Error adding new purchase: ${response.message}`)
        }
      } catch (error) {
        // Error 😨
        if (error.response) {
          toastError(toast, error.response.data.message)
          console.log('postSite sub', error.response.data)
          console.log('postSite sub', error.response.status)
          console.log('postSite sub', error.response.headers)
        } else if (error.request) {
          console.log('postSite sub', error.request)
          toastError(toast, 'Error adding new purchase in request')
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('postSite sub', 'Error', error.message)
          toastError(toast, 'Error adding new purchase: Something happened in setting up the request and triggered an Error')
        }
      } finally {
        // Clear the submission state
        submitOnProcess.value.save = false
      }
    }

    const onSubmitToDraft = () => {
      submitOnProcess.value.todraft = true
      const payload = JSON.parse(JSON.stringify(transactionData.value))
      payload.actiontype = 'todraft'
      payload.status = 'Draft'
      payload.order_id = router.currentRoute.params.id
      // payload.supplier = payload.supplier.contact
      // payload.activity = payload.activity.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.activity = payload.activity.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.responsible = payload.responsible.id
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      payload.usersEdit = usersEdit.value
      // payload.file = transactionData.value.file
      console.log(payload)
      store.dispatch('app-transaction/createSale', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          router.push({ name: 'apps-transaction-preview', params: { id: response.data.sale_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.todraft = false
        })
    }

    // const onSubmitWeight = index => {
    //   if (totalWeightActivator.value === false) {
    //     transactionData.value.items[index].weight = transactionData.value.items[index].subItems.reduce((total, item) => total + parseFloat(item.weight), 0)
    //   }
    // }

    const paymentMethods = [
      'Bank Account',
      'Uzcard',
      'Cash',
    ]

    const resolveBalanceColor = value => {
      if (value > 0) return 'success'
      if (value === 0) return 'primary'
      if (value < 0) return 'danger'
      return 'secondary'
    }

    // const totalPrice = computed(() => transactionData.value)

    // const fileSubmit = val => {
    //   const formData = new FormData()
    //   formData.append('file', val)
    //   store.dispatch('app-transaction/addFile', formData)
    //     .catch(error => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error adding new purchase',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //           text: error.message,
    //         },
    //       })
    //     })
    // }

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'danger'
    }

    return {
      roundUp,
      roundUpCustom,
      transactionData,
      suppliers,
      activities,
      users,
      suboptions,
      updateSubItemQty,
      itemFormBlankItem,
      paymentMethods,
      totalWeightActivator,
      t,
      // onSubmitWeight,
      onSubmitSave,
      // onSubmitSaveSell,
      onSubmitToDraft,
      submitOnProcess,
      refFormObserver,
      getValidationState,
      resetForm,
      refetchSuppliers,
      refetchActivities,
      refetchSubOptions,
      refSupplierOpt,
      refActivityOpt,
      refCarOpt,
      refSubOpt,
      resolveBalanceColor,
      // totalPrice,
      refRespOpt,
      // fileSubmit,
      transactionDataLoading,
      resolveUserAvatarVariant,
      avatarText,
      getSubOptions,
      accounts,
      refetchKassas,
      receivers,
      refetchRec,
      refetchSubOpt,
      fieldsEdit: [
        {
          id: 1, key: 'state', label: 'Статья', type: 'text', editable: false
        },
        {
          id: 2, key: 'amount_usd', label: 'Сумма USD:', type: 'number', editable: false
        },
        {
          id: 3, key: 'amount_uzs', label: 'Сумма UZS:', type: 'number', editable: false
        },
        {
          id: 4, key: 'subrate', label: 'Курс:', type: 'number', editable: true
        },
        {
          id: 5, key: 'commision', label: 'Коммисия %:', type: 'number', editable: true
        },
      ],
      usersEdit,
      loadingEdit: false,
      // handleInput,
      formatterCus(data) {
        console.log(data)
      },
      handleChange,
      updateNumericValue(event) {
        if (
          event.keyCode === 46 // delete
            || event.keyCode === 8 // backspace
            || event.keyCode === 9 // tab
            || event.keyCode === 27 // escape
            || event.keyCode === 13 // enter
            || event.keyCode === 190 // decimal point
            || (event.keyCode >= 48 && event.keyCode <= 57) // numeric keys
            || (event.keyCode >= 96 && event.keyCode <= 105) // numeric keypad
        ) {
          return true
        }
        // Ignore all other characters
        event.preventDefault()
        return false
      },
      formatAmount,
      supplierOptions,
      getItemSupplier(val, item) {
        console.log(val, item)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
